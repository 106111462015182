<template>
  <a-form-model ref="form" :model="form" v-bind="$formItemLayout">
    <a-form-model-item
      label="阶段名称"
      prop="name"
      :rules="{
        required: true,
        message: '请填写阶段名称',
        trigger: 'blur'
      }"
    >
      <a-input v-model.trim="form.name" :maxLength="25" />
    </a-form-model-item>
    <a-form-model-item label="教学视频" prop="teachingVideoId">
      <div>
        <single-upload
          :accept="['mp4']"
          :limit="1024 * 1024 * 500"
          @success="handleVideoUrl"
          :isDownload="false"
        ></single-upload>
        <a-row v-if="uploadInfo && uploadInfo.url" style="margin:8px 0;line-height:normal" type="flex" align="middle">
          <a-col flex="1" style="text-overflow: ellipsis;white-space:nowrap;overflow:hidden;">
            <a
              target="blank_"
              style="text-overflow: ellipsis;white-space:nowrap;overflow:hidden;"
              @click="handlePreviewVideo(uploadInfo.url)"
            >
              <a-icon type="file" style="margin-right:8px" />{{ uploadInfo && uploadInfo.name }}
            </a>
          </a-col>
          <a-col class="error-color" style="margin-left:16px;cursor:pointer" @click="handleDeleteUploadInfo">
            <a-icon type="delete" class="error-color" />
          </a-col>
        </a-row>
        <div class="disabled-color" style="line-height:1.5">
          提示：MP4格式文件，大小不超过500MB
        </div>
      </div>
    </a-form-model-item>

    <a-form-model-item
      label="阶段分类"
      prop="fieldId"
      :rules="
        form.id
          ? {}
          : {
              required: true,
              message: '请选择阶段分类',
              trigger: 'blur'
            }
      "
    >
      <h-cascader
        v-if="!form.id"
        v-model="form.fieldId"
        :options="fieldsOptions"
        :fieldNames="{ label: 'name', value: 'id', children: 'children' }"
        placeholder="请选择"
      />
      <span v-else>
        {{
          form.field.name
            ? form.field.parent
              ? form.field.parent.name + ' > ' + form.field.name
              : form.field.name
            : '-'
        }}
      </span>
    </a-form-model-item>

    <a-form-model-item
      label="记录方式"
      prop="recordingMethod"
      :rules="
        form.id
          ? {}
          : {
              required: true,
              message: '请选择记录方式',
              trigger: 'blur'
            }
      "
    >
      <h-select v-if="!form.id" v-model="form.recordingMethod" placeholder="请选择">
        <a-select-option
          v-for="recodingMethodInfo in recordingMethodOptions"
          :key="recodingMethodInfo.value"
          :value="recodingMethodInfo.value"
        >
          {{ recodingMethodInfo.label }}
        </a-select-option>
      </h-select>
      <span v-else>{{ form.recordingMethod | RecordingMethodText() }}</span>
    </a-form-model-item>

    <a-form-model-item
      label="阶段目标"
      prop="introduction"
      :rules="{
        required: true,
        message: '请填写阶段介绍',
        trigger: 'blur'
      }"
    >
      <div style="position:relative">
        <a-input
          v-model="form.introduction"
          placeholder="请输入内容"
          :maxLength="900"
          :autoSize="{ minRows: 6, maxRows: 6 }"
          type="textarea"
        />
        <div style="position:absolute;bottom:-5px;right:1px;">
          <span style="background:#fff">{{ form.introduction.length }}/900</span>
        </div>
      </div>
    </a-form-model-item>

    <a-form-model-item
      v-for="(stepsDescInfo, index) in form.stepsDescriptionList"
      :key="index"
      v-bind="index !== 0 ? $formItemLayoutWithoutLabel : {}"
      :label="index === 0 ? '步骤描述' : ''"
      :prop="'stepsDescriptionList.' + index + '.value'"
      :rules="{
        required: true,
        message: '请填写步骤描述',
        trigger: 'blur'
      }"
    >
      <a-input v-model="stepsDescInfo.value" :placeholder="`请输入第${index + 1}步`" :maxLength="900" />
      <a-icon
        v-if="form.stepsDescriptionList.length > 1"
        class="dynamic-delete-button"
        type="minus-circle-o"
        :disabled="form.stepsDescriptionList.length === 1"
        @click="removeStepDesc(stepsDescInfo)"
      />
    </a-form-model-item>

    <a-form-model-item v-bind="$formItemLayoutWithoutLabel">
      <a-button type="dashed" style="width: 100%" @click="addStepDesc"><a-icon type="plus" />新增步骤</a-button>
    </a-form-model-item>

    <a-form-model-item label="注意事项">
      <div style="position:relative">
        <a-input
          v-model="form.precautions"
          placeholder="请输入内容"
          :maxLength="900"
          :autoSize="{ minRows: 6, maxRows: 6 }"
          type="textarea"
        />
        <div style="position:absolute;bottom:-5px;right:1px;">
          <span style="background:#fff">{{ form.precautions.length }}/900</span>
        </div>
      </div>
    </a-form-model-item>

    <a-form-model-item label="状态">
      <a-radio-group v-model="form.status">
        <a-radio v-for="statusInfo in statusOptions" :key="statusInfo.value" :value="statusInfo.value">
          {{ statusInfo.label }}
        </a-radio>
      </a-radio-group>
    </a-form-model-item>

    <a-form-model-item
      label="上传动画"
      prop="resourceList"
      :rules="{
        required: true,
        message: '请上传动画',
        trigger: 'change'
      }"
      style="margin-bottom: 64px"
    >
      <zip-upload
        @success="handleResourcesSuccess"
        @error="handleResourcesError"
        @change="handleResourcesChange"
      ></zip-upload>
      <div v-if="form.resourceList.length > 0" style="margin-top:16px">
        <div v-for="item in form.resourceList" :key="item.id" style="margin-top:8px;line-height:normal">
          <a v-if="item.type === 'VIDEO'" @click="handlePreviewVideo(item.url)" target="blank_"
            ><a-icon type="file" style="margin-right:8px" />{{ item.name }}</a
          >
          <a v-else :href="item.url" target="blank_"><a-icon type="file" style="margin-right:8px" />{{ item.name }}</a>
        </div>
      </div>
      <div v-if="resourceIdsErr" slot="extra" class="error-color">{{ resourceIdsErr }}</div>
    </a-form-model-item>

    <a-form-model-item v-if="form.resourceList.length > 0" v-bind="$formItemLayoutWithoutLabel" prop="trainingStep">
      <a-row type="flex" align="middle">
        <a-col>本素材动画共分为</a-col>
        <a-col>
          <a-input v-model="form.steps" :disabled="true" style="width:80px;text-align:center;margin:0 16px;"></a-input>
        </a-col>
        <a-col>步，数据记录将出现在第</a-col>
        <a-col>
          <a-input-number
            v-model="form.trainingStep"
            :min="0"
            :max="form.steps"
            style="width:80px;margin:0 16px;"
          ></a-input-number>
        </a-col>
        <a-col>步后</a-col>
      </a-row>
    </a-form-model-item>

    <a-form-model-item
      v-if="form.resourceList.length > 0"
      v-bind="$formItemLayoutWithoutLabel"
      prop="minimumRounds"
      style="margin-bottom: 64px"
    >
      <a-row type="flex" align="middle">
        <a-col>最低回合数</a-col>
        <a-col>
          <a-input-number
            v-model="form.minimumRounds"
            :min="0"
            :max="10"
            style="width:80px;margin:0 16px;"
          ></a-input-number>
        </a-col>
        <a-col>回合<span class="disabled-color">（输入回合数不超过10）</span></a-col>
      </a-row>
    </a-form-model-item>
  </a-form-model>
</template>
<script>
import { cloneDeep } from 'lodash'
import * as commonApi from '@/api/common'
import { previewVideo } from '@/utils/util'
import { RECORDING_METHOD, COMMON_STATUS } from '@/store/dictionary'
import { ZipUpload, SingleUpload } from '@/components/_heath'

export default {
  components: {
    ZipUpload,
    SingleUpload
  },
  data() {
    return {
      form: {
        id: undefined,
        name: '',
        field: {},
        fieldId: [],
        recordingMethod: undefined,
        introduction: '',
        stepsDescriptionList: [{ value: '' }],
        precautions: '',
        status: 'AVAILABLE',
        resourceList: [],
        resourceIds: [],
        steps: 0,
        trainingStep: 0,
        minimumRounds: 0,
        teachingVideoId: ''
      },
      fieldsOptions: [],
      recordingMethodOptions: RECORDING_METHOD,
      statusOptions: COMMON_STATUS,
      resourceIdsErr: '',
      uploadInfo: {
        // name: '', // 文件名
        // size: '', // 文件大小
        // suffix: '', // 文件后缀名
        // type: '', // 文件类型：VIDEO，IMAGE，AUDIO,PDF
        // url: '', // 阿里云地址
        // value: '' // MD5值
      }
    }
  },
  activated() {
    this.initOptions()
  },
  methods: {
    initData(form) {
      const {
        id,
        name,
        field,
        recordingMethod,
        introduction,
        precautions,
        status,
        resourceList,
        steps,
        trainingStep,
        minimumRounds,
        teachingVideo: uploadInfo
      } = form

      let { stepsDescriptionList } = form
      stepsDescriptionList = stepsDescriptionList.map(v => ({
        value: v
      }))
      this.uploadInfo = uploadInfo
      this.form = {
        id,
        name,
        field,
        recordingMethod,
        introduction,
        stepsDescriptionList,
        precautions,
        status,
        resourceList,
        steps,
        trainingStep,
        minimumRounds,
        teachingVideoId: uploadInfo && uploadInfo.id
      }
    },
    initOptions() {
      commonApi.fieldsOptions().then(res => {
        this.fieldsOptions = res.data.filter(v => v.level === 'MAIN').filter(v => v.children.length > 0)
      })
    },
    formatForm() {
      const ret = cloneDeep(this.form)

      const { field, resourceList } = ret
      let { fieldId, stepsDescriptionList } = ret

      if (ret.id) {
        fieldId = field.id
        delete ret.field
      } else {
        fieldId = fieldId[1]
      }
      stepsDescriptionList = stepsDescriptionList.map(v => v.value)
      const resourceIds = resourceList.map(v => v.id)

      return { ...ret, fieldId, stepsDescriptionList, resourceIds }
    },
    addStepDesc() {
      this.form.stepsDescriptionList.push({ value: '' })
    },
    removeStepDesc(item) {
      const index = this.form.stepsDescriptionList.indexOf(item)
      if (index !== -1) {
        this.form.stepsDescriptionList.splice(index, 1)
      }
    },
    handleResourcesSuccess(data) {
      this.form.resourceList = data
      this.form.steps = data.length
      this.trainingStep = 0
    },
    handleResourcesError(err) {
      this.resourceIdsErr = err
      this.form.resourceList = []
      this.form.steps = 0
      this.trainingStep = 0
    },
    handleResourcesChange() {
      const { $nextTick, $refs } = this

      this.resourceIdsErr = ''
      this.form.resourceList = []
      this.form.steps = 0
      this.trainingStep = 0

      $nextTick(() => {
        $refs.form.clearValidate('resourceList')
      })
    },
    // 上传的文件信息
    handleVideoUrl(uploadInfo) {
      this.uploadInfo = uploadInfo
    },
    // 上传
    resources() {
      return new Promise((resolve, reject) => {
        commonApi
          .resources(this.uploadInfo)
          .then(res => {
            this.form.teachingVideoId = res.data.id
            resolve()
          })
          .catch(err => {
            console.log(err)
            reject()
          })
      })
    },
    // 删除教学视频
    handleDeleteUploadInfo() {
      this.uploadInfo = {}
      this.form.teachingVideoId = ''
    },
    //
    handlePreviewVideo(url) {
      previewVideo(url)
    }
  }
}
</script>
<style lang="less" scoped>
/deep/ .ant-form-item-children {
  position: relative;
}
/deep/ .ant-input-number-input {
  text-align: center;
}

.dynamic-delete-button {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(200%, -50%);
  &:hover {
    color: #f5222d;
  }
}
</style>
